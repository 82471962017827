import * as React from "react";
import type { GatsbyBrowser } from "gatsby";
import { WrapRootElement } from "./src/provider";
import Clarity from "@microsoft/clarity";

const projectId = "q5rz4qo61t";
Clarity.init(projectId);
window.Clarity = Clarity;

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => <WrapRootElement element={element} />;

export const onClientEntry = () => {
  if (process.env.NODE_ENV === "development") {
    console.log("Injecting development script for message passing...");

    const script = document.createElement("script");
    script.textContent = `
       (function() {
         function addEventListeners() {
           document.querySelectorAll("[data-element]").forEach(el => {
             // Add hover effect only to the directly hovered element
             el.addEventListener("mouseenter", (event) => {
               event.stopPropagation();
               el.style.outline = "2px dashed yellow";
 
               // Add tabindex only if it doesn't exist
               if (!el.hasAttribute("tabindex")) {
                 el.setAttribute("tabindex", "0");
               }
             });
 
             // Remove outline and tabindex on mouse leave
             el.addEventListener("mouseleave", (event) => {
               event.stopPropagation();
               el.style.outline = "";
               el.removeAttribute("tabindex");
             });
 
             // Add focus effect (keyboard navigation)
             el.addEventListener("focus", (event) => {
               event.stopPropagation();
               el.style.outline = "2px dashed yellow";
             });
 
             // Remove outline on blur
             el.addEventListener("blur", (event) => {
               event.stopPropagation();
               el.style.outline = "";
             });
 
             // Send message to parent frame on click
             el.addEventListener("click", (event) => {
               event.stopPropagation();
 
               const elementType = el.getAttribute("data-element") || "unknown";
               const line = el.getAttribute("data-line") || "unknown";
               const column = el.getAttribute("data-column") || "unknown";
 
               const message = {
                 type: "chakra-element-click",
                 element: elementType,
                 line: line,
                 column: column
               };
 
               if (window.parent !== window) {
                 window.parent.postMessage(message, "*"); // Send to parent frame
               }
 
               console.log("Sent message to parent frame:", message);
             });
           });
         }
 
         // Run once on load
         addEventListeners();
 
         // Re-run when the DOM updates (for SPAs)
         const observer = new MutationObserver(() => {
           addEventListeners();
         });
 
         observer.observe(document.body, { childList: true, subtree: true });
       })();
     `;

    document.head.appendChild(script);
  }
};
